import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-458a77ac")
const _hoisted_1 = { class: "p-field p-col-12 p-d-flex p-flex-column uploaderRev" }
const _hoisted_2 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileUpload = _resolveComponent("FileUpload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FileUpload, {
      class: "ch-fileupload",
      name: "file",
      mode: "basic",
      accept: _ctx.fileType,
      chooseLabel: "Agregar/Editar",
      customUpload: true,
      auto: true,
      onUploader: _ctx.onUpload,
      onUpload: _ctx.onUploaded
    }, null, 8, ["accept", "onUploader", "onUpload"]),
    (_ctx.dataUploaded.logoUrl)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "logo-img",
          src: _ctx.dataUploaded.logoUrl,
          alt: ""
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}