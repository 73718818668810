
import { defineComponent, reactive, PropType, watch } from 'vue'
import FileUpload from 'primevue/fileupload'
import { uploadSvg, saveWwwImage } from '@/api/www.api'
import { useToast } from 'primevue/usetoast'
import { ToastLife } from '@/models/components/toast/enums'
import { SvgProcessingOptions } from '@/models/www/SvgUploader'

export default defineComponent({
  name: 'UploadSvg',
  emits: ['update:modelValue', 'setLoader'],
  props: {
    modelValue: {
      type: String,
      required: true
    },
    processingOptions: {
      type: Object as PropType<SvgProcessingOptions>
    },
    fileType: {
      type: String,
      required: false,
      default: 'image/svg+xml'
    }
  },
  components: {
    FileUpload
  },
  setup (props, { emit }) {
    const toast = useToast()
    watch(props, () => {
      if (props.modelValue) {
        dataUploaded.logoUrl = props.modelValue
      }
    })

    const dataUploaded = reactive({ logoUrl: props.modelValue || '' })

    const onUploaded = (xhr: XMLHttpRequest, files: any) => {
      console.log({ xhr, files })
    }

    const onUpload: (event: { files: File[] }) => Promise<void> = async (event) => {
      emit('setLoader', true)
      try {
        const response = props.fileType === 'image/svg+xml' ? await uploadSvg(event.files[0], props.processingOptions) : await saveWwwImage(event.files[0])
        dataUploaded.logoUrl = response.url
        emit('update:modelValue', dataUploaded.logoUrl)
      } catch (error) {
        console.log(error)
        toast.add({
          severity: 'error',
          summary: 'Error al subir el archivo',
          detail: (error instanceof Error && error.message) || '',
          life: ToastLife.Default
        })
      }
      emit('setLoader', false)
    }
    return {
      dataUploaded,
      onUpload,
      onUploaded
    }
  }
})
