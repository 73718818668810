
import { defineComponent, ref, Ref, reactive, watch, onMounted } from 'vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import LogoUploader from '@/components/uploadSvg/upload.vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Dropdown from 'primevue/dropdown'
import { CompaniesAPI } from '@/api/companies.api'

export default defineComponent({
  emits: ['nextPage', 'prevPage', 'cancel'],
  props: {
    formData: {
      type: Object
    },
    isEditing: {
      type: Boolean
    }
  },
  components: {
    InputText,
    Button,
    LogoUploader,
    Dropdown
  },
  setup (props, { emit }) {
    const sectors: Ref<{name: string, value: string}[]> = ref([])
    const state = reactive({
      name: '',
      sector: ''
    })

    const logo = ref()
    const imageNotUploaded = ref(true)
    watch(props, () => {
      if (props.isEditing) {
        const newState = reactive({
          name: props.formData?.name,
          sector: props.formData?.sector
        })
        Object.assign(state, newState)
        logo.value = props.formData?.logo
        imageNotUploaded.value = false
      }
    })

    const rules = {
      name: { required },
      sector: { required }
    }
    const imageError = ref(false)

    watch(imageNotUploaded, (current, old) => {
      imageError.value = imageNotUploaded.value
    })
    const v$ = useVuelidate(rules, state)

    const next = async () => {
      v$.value.$touch()

      const isFormCorrect = await v$.value.$validate()
      if (!isFormCorrect) {
        if (imageNotUploaded.value) {
          imageError.value = true
        }
        return
      }
      if (imageNotUploaded.value) {
        imageError.value = true
        return
      }
      const { name, sector } = v$.value
      emit('nextPage', { formData: { name: name.$model, sector: sector.$model, logo: logo.value }, pageIndex: 0 })
    }
    const back = () => { emit('prevPage', { pageIndex: 0 }) }
    const cancel = () => { emit('cancel') }

    const toggleImage = (url: string) => {
      imageNotUploaded.value = false
      logo.value = url
    }

    const getCompanyDetails = async () => {
      const details = await CompaniesAPI.getCompanyDetail('area')
      sectors.value = details.map(({ value }: {value: string}) => {
        return { name: value, value }
      })
      sectors.value.push({ name: 'Otros', value: 'Otros' })
    }

    onMounted(getCompanyDetails)

    return {
      imageNotUploaded,
      toggleImage,
      back,
      next,
      cancel,
      v$,
      state,
      sectors,
      imageError
    }
  }
})
